import { Breadcrumbs, Typography } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { datadogLogs } from '@datadog/browser-logs'

interface NavBreadcrumbsProps {
    currentView?: string;
}

const NavBreadcrumbs = ({ currentView }: NavBreadcrumbsProps) => {
    const location = useLocation(); // get current location
    const pathnames = location.pathname.split("/").filter((x) => x);

    useEffect(() => {
        console.log("Breadcrumb pathnames", pathnames);
        datadogLogs.logger.info('User Navigated to:', { path: pathnames })
    }, [location]);

    const dynamicBreadcrumbNameMap: { [key: string]: string } = {
        "/about": "About",
        "/products": "Products",
        "/payinvoices": "Pay Invoices",
        "/viewinvoices": "View Invoices",
        "/printinvoices": "Print Invoices",
        "/printtickets": "Print Tickets",
        "/paymethods": "Payment Methods",
        "/contact": "Contact",
        "/admin": "Admin Dashboard",
        "/servicerequests": "Service Requests",
        "/cart": "Shopping Cart",
        "/cart/checkout": "Checkout",
        // Dynamic breadcrumb name based on currentView, still a work in progress. Currently can't dynamicaly add color to a portion of the Typography component. Will have to create a custom styling component to do so.
        /*
        "/payments":
            currentView === "makePayments" ? "Payments > Make Payments"
            : currentView === "payInvoices" ? "Payments > Pay Invoices"
            : "Payments",
        */
        /*
            "/reports":
            currentView === "printTickets" ? "Reports > Print Tickets"
            : currentView === "printInvoices" ? "Reports > Print Invoices"
            : "Reports",
        */
        "/payments": "Payments",
        "/reports": "Reports",
    };

    interface LinkRouterProps extends LinkProps {
        to: string;
        replace?: boolean;
    }

    function LinkRouter(props: LinkRouterProps) {
        return (
            <Link
                {...props}
                component={RouterLink as any}
            />
        );
    }

    return (
        <Breadcrumbs
            aria-label='breadcrumb'
            separator='>'
            sx={{
                mt: 4.5,
                fontFamily: "Montserrat Regular",
                color: "#1E2832",
                fontWeight: 400,
                fontSize: "14px",
            }}
        >
            <LinkRouter
                underline='hover'
                color='inherit'
                to='/'
            >
                Home
            </LinkRouter>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ?
                    <Typography
                        color='#1E2832'
                        fontFamily={"Montserrat Regular"}
                        fontWeight={500}
                        fontSize={"14px"}
                        key={to}
                    >
                        {dynamicBreadcrumbNameMap[to]}
                    </Typography>
                    : <LinkRouter
                        underline='hover'
                        color='inherit'
                        to={to}
                        key={to}
                    >
                        {dynamicBreadcrumbNameMap[to]}
                    </LinkRouter>;
            })}
        </Breadcrumbs>
    );
};

export default NavBreadcrumbs;
