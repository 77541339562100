// File: src/services/GetInvoiceData.tsx

import { fetchAuthSession } from "aws-amplify/auth";
import { getHostnameDataOrDefault } from "../helpers/TenantsDB";
import { CCProfile } from "../interfaces/InvoiceInterfaces";
import { SelectHubsByTenantID, SelectTenant } from "./GraphQlHelper";
import { TenantBySubdomainQuery, TenantBySubdomainQueryVariables, Tenants } from "API";
import { hubByTenantId } from "graphql/queries";
import React, { useContext } from "react";
import { IdentityConfigs } from "identity/helpers/configs";
// Import all imports under the IdentityImports namespace.
import * as IdentityImports from "../identity/helpers/imports";
//import { Settings } from "@mui/icons-material";

//var Buffer = require('buffer').Buffer

//import * as crypto from 'crypto';

/*These values are hardcoded for testing, but should be set based on the logged in user.*/
//const url = "https://pvsapi.prismvs.com/";
//const authuserid = "dgikow";
//const authKey = "DWJHDKJHWY#%444bghjc((*&^%%%3eewrewooooo**054";
//const username = "webcustomer@prismvs.com";
//const companyname = "Prism";
//const companylogo = "prism-visual-software-logo.svg";

/*SettingsExpirationMinutes is the amount of time in minutes that the settings are stored.  After that time it will reload the settings.*/
const SettingsExpirationMinutes = 60;
const TokenExpirationMinutes = 5;

let url = "";
let authuserid = "";
let authKey = "";
let tenant = "";
let username = "";
let companyname = "";
let companylogo = ""; //"https://www.prismvs.com/hubfs/raw_assets/public/Prism_Visual_Software_September2021/images/prism-visual-software-logo.svg";
let companySideImage = "";
let parsedToken: any;
let cognitoauthtoken: string | null = "";
const defaultLogo = "https://prism-web-images.s3.amazonaws.com/logos/prism.jpg";
const defaultSideImage = "https://prism-web-images.s3.amazonaws.com/login_images/prism.jpg";

/*These should remain constant*/
const ENDPOINT_Auth = "api/Prism/Authenticate";
const ENDPOINT_OpenInv = "api/Prism/V1/Invoices/GetOpenInvoices";
const ENDPOINT_PayMethods = "api/Prism/V1/Payment/GetPaymentProfiles";
const ENDPOINT_SetPONum = "api/Prism/V1/Invoices/UpdatePONumber";
const ENDPOINT_PayInvoices = "api/Prism/V1/Payment/PayInvoices";
const ENDPOINT_GetCustomerCredits = "api/Prism/V1/Customer/GetCustomerCredits";
const ENDPOINT_SaveCCProfile = "api/Prism/V1/Payment/SaveCCProfile";
const ENDPOINT_GetScheduleAddress = "api/Prism/V1/Customer/GetScheduleAddresses";
const ENDPOINT_GetSurcharge = "api/Prism/V1/Payment/GetInvoiceSurcharge";
const ENDPOINT_AddItemsToSchedule = "api/Prism/Tickets/AddItemsToSchedule";
const ENDPOINT_CreateTicket = "api/Prism/Tickets/CreateTicket";
const ENDPOINT_GetSuggestedItems = "api/Prism/V1/Inventory/GetSuggestedItems";
const ENDPOINT_GetScheduleHeader = "api/Prism/V1/Schedules/GetScheduleHeader";
const ENDPOINT_GetScheduleCaseCount = "api/Prism/V1/Schedules/GetScheduleCaseCount";
const ENDPOINT_GetInvoiceReport = "api/Prism/V1/Invoices/GetInvoiceReport";
const ENDPOINT_GetUserInvoices = "api/Prism/V1/Invoices/GetUserInvoices";
const ENDPOINT_GetTicketReport = "api/Prism/V1/Tickets/GetTicketReport";
const ENDPOINT_GetUserTickets = "api/Prism/V1/Tickets/GetUserOrders";
const ENDPOINT_CreateServiceTicket = "api/Prism/V1/Tickets/CreateServiceTicket";
const ENDPOINT_GetWebCategories = "api/Prism/V1/Inventory/GetWebCategories";
const ENDPOINT_GetWebItems = "api/Prism/V1/Inventory/GetWebItems";
const ENDPOINT_GetLogoURL = "api/Prism/V1/GetLogoURL";
const ENDPOINT_RegisterNewUser = "api/Prism/V1/Customer/RegisterNewUser";
const ENDPOINT_RemoveCustomerFromUser = "api/Prism/V1/Customer/RemoveCustomerFromUser";
const ENDPOINT_GetCustomers = "api/Prism/V1/Customer/GetUserLinkedCustomers";
const ENDPOINT_GetSettings = "api/Prism/V1/GetSettings";
const ENDPOINT_SaveSettings = "api/Prism/V1/SaveSettings";
const ENDPOINT_GetNextDeliveryDate = "api/Prism/V1/Schedules/GetNextDeliveryDate";
const ENDPOINT_GetUsersHubs = "api/Prism/V1/Customer/GetUsersHubs";
const ENDPOINT_SetDefaultCCProfile = "api/Prism/V1/Payment/SetDefaultCCProfile";
const ENDPOINT_DeleteCCProfile = "api/Prism/V1/Payment/DeleteCCProfile";
const ENDPOINT_GetSpecialImages = "api/Prism/V1/Inventory/GetSpecialImages";
const ENDPOINT_GetCustomerCheckoutInfo = "api/Prism/V1/Customer/GetCustomerCheckoutInfo";
const ENDPOINT_SaveSpecialImage = "api/Prism/V1/Inventory/UpdateInsertSpecialImage";
//const ENDPOINT_PayByCreditCard = "api/Prism/V1/Payment/PayByCreditCard";

export type Settings = {
    name: string;
    value: string;
    type?: string;
    label?: string;
    truevalue?: string;
    falsevalue?: string;
};
export const defaultSettings: Settings[] = [
    {
        name: "SendEmailConfirmationsToCustomers",
        value: "1",
        type: "checkbox",
        label: "Send Email Confirmations To Customers",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "SendEmailConfirmationsToClient",
        value: "1",
        type: "checkbox",
        label: "Send Email Confirmations To Client",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "EmailConfirmationsToClientAddress",
        value: "",
        type: "textbox",
        label: "Client Email Address for Confirmations",
    },
    {
        name: "showviewopeninvoices",
        value: "1",
        type: "checkbox",
        label: "Show Web Bill Pay",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showmainstore",
        value: "1",
        type: "checkbox",
        label: "Show Web Store",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showmodifycreditcards",
        value: "1",
        type: "checkbox",
        label: "Show Modify Credit Cards",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showdashboardpage",
        value: "1",
        type: "checkbox",
        label: "Show Dashboard",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showinvoicereport",
        value: "1",
        type: "checkbox",
        label: "Show Invoice Report",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showticketreport",
        value: "1",
        type: "checkbox",
        label: "Show Web Store",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showservicerequests",
        value: "1",
        type: "checkbox",
        label: "Show Service Requests",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showreports",
        value: "1",
        type: "checkbox",
        label: "Show Service Requests",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "ShowPrices",
        value: "1",
        type: "checkbox",
        label: "Show Prices",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "WebsiteLogo",
        value: "",
        type: "textbox",
        label: "Webstore Main Logo",
    },
    {
        name: "AllowWebstorePaymentByCreditCard",
        value: "0",
        type: "checkbox",
        label: "Allow customers to place orders using credit card.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "ShowNextDeliveryDate",
        value: "0",
        type: "checkbox",
        label: "Show Next Delivery Date on web store.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "defaultpage",
        value: "mainstore",
        type: "textbox",
        label: "Default Landing Page (mainstore, viewopeninvoices or dashboardpage)",
    },
    {
        name: "ShowAllItemsForEntireCatalog",
        value: "0",
        type: "checkbox",
        label: "Show All items for entire catalog, regardless of what is on schedule.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "TenantID",
        value: "",
        type: "textbox",
        label: "The Tenant ID for this database.",
    },
    {
        name: "enableDataDogMonitoring",
        value: "false",
        type: "checkbox",
        label: "Enable Logging",
        truevalue: "true",
        falsevalue: "false"
    },
];
/*
function getCookie(name: string): string | null {
    console.log("Get Cookie Called.");
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        console.log(c);
        let ind = c.indexOf(nameEQ);
        if(ind >= 0){
            c = c.substring(ind, c.length);
            console.log("substring val: " + c);
            //while (c.indexOf(nameEQ) !== 0 && c.length>nameEQ.length) c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}*/

/*
function getCookie2(name: string): string | null {
    console.log("Get Cookie Called.");
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        console.log(c);
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
*/

export async function getSecrets2() {
    /*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        await setAWSTokens();
        console.log("getSecrets2: AccessToken: ", cognitoauthtoken);
    }
    else
    {
        console.log("Production: asl;dfkj;laskjdf;laskjdf;laskjdf;lasdkjf;laksjdf;lkajsdf;lksajd;lkjlsdkfjlkdjflknvlkdnslkfjlsakjdflksajdf;lkasjdf;lkajf;lkasnv;lkvn;laskjd;lsakdj;laskdj;laskdj;lasdkjf;laskdjf;lasdkjf;lasdjf;lasdfjk");
        tokenID = getCookie("idToken");
        cognitoauthtoken = getCookie("accessToken");
        console.log("atk: " + cognitoauthtoken);
        parsedToken = parseJwt(tokenID);

        tenant = parsedToken["custom:TenantId"];
        companyname = parsedToken["custom:Company"];
        cognitoclientid = parsedToken["aud"];
        cognitodomain = "test-prismvs.auth.us-east-1.amazoncognito.com";
        username = parsedToken.email;
        localStorage.setItem('user', username);
    
    }*/
    const tenantObj = await GetTenantInfo();
    await fetchSecrets();
    await setAWSTokens();

    if (tenantObj) {
        url = tenantObj.url;
        SetSettings();
    }

    console.log("Tenant ID: " + tenant);
    console.log("User: " + username);

    /*const secrets = await getSecretValue("TenantID_" + tenant);
    const jsonSecrets = JSON.parse(secrets);
    url = jsonSecrets?.URL;
    if(!url.endsWith("/"))
        url = url + "/";
    authuserid = jsonSecrets?.User;
    authKey = jsonSecrets?.AuthKey;*/

    console.log("URL: " + url);
    console.log("User: " + authuserid);
    console.log("AuthKey: " + authKey);
    console.log("username: " + username);
    //SetLogoURL();
}

async function getHubData(tenantData: any) {
    var hub = localStorage.getItem("hub");
    console.log("getHubData: hub: " + hub);
    if (!hub || hub === "" || !tenantData.usesHubs) {
        console.log("Hub not found from localStorage or hubs is turned off: ");
        return tenantData;
    }

    var hubs = await SelectHubsByTenantID(tenantData.subdomain);
    if (!hubs) {
        console.log("Hubs not found: " + hub);
        return tenantData;
    }
    var hubData = hubs.data.hubByTenantId.items.find(
        (x) => x.chubno.trim().toLowerCase() === hub?.trim().toLowerCase(),
    );
    if (!hubData) {
        console.log("Hub not found: " + hub);
        return tenantData;
    }

    tenantData.address1 = hubData.address1;
    tenantData.address2 = hubData.address2;
    tenantData.city = hubData.city;
    tenantData.state = hubData.state;
    tenantData.zip = hubData.zip;
    tenantData.companyname = hubData.companyname;
    tenantData.email = hubData.email;
    tenantData.phone = hubData.phone;
    if (hubData.logo !== "") tenantData.logo = hubData.logo;

    console.log("hub:  tenantData: ", tenantData);
    return tenantData;
}

interface Tenant {
    subdomain: string;
    hub: string;
    date: Date;
    data: any;
}

var tenantData: Tenant[] = [];

export async function GetTenantInfoFromName(tenantName: string) {
    var hubStrg = localStorage.getItem("hub");
    const hub = hubStrg ? hubStrg : "";
    try {
        if (tenantData && tenantData.find((x) => x.subdomain === tenantName && x.hub === hub)) {
            var tnt = tenantData.find((x) => x.subdomain === tenantName && x.hub === hub);
            if (tnt && new Date().getUTCHours() - tnt?.date.getUTCHours() > 1) tenantData = [];
            else {
                console.log("GetTenantInfoFromName: Tenant found in cache: ", tnt?.data);
                localStorage.setItem("tenant", JSON.stringify(tnt?.data));
                localStorage.setItem("tenantURL", tnt?.data?.url);
                isConnectedToTenant = true;
                return tnt?.data;
            }
        }

        const td = localStorage.getItem("tenantDate");

        if (td && new Date().getUTCHours() - new Date(td).getUTCHours() <= 1) {
            const tntd = localStorage.getItem("tenant");
            const tntdo = JSON.parse(tntd!);
            tenantData?.push({
                subdomain: tntdo.subdomain,
                hub: hub,
                date: new Date(),
                data: tntdo,
            });
            console.log("GetTenantInfoFromName: Tenant found in localStorage: ", tntdo);
            isConnectedToTenant = true;
            return tntdo;
        }
    } catch (err) {
        console.log("GetTenantInfoFromName: Error: ", err);
    }

    IdentityImports.Amplify.configure(IdentityConfigs.amplifyConfig);
    const res = await SelectTenant(tenantName);
    console.log("GetTenantInfoFromName: Tenant= ", res);
    localStorage.setItem("tenantURL", "");
    localStorage.setItem("tenant", "");
    if (
        !res?.data?.tenantBySubdomain?.items[0] ||
        res.data.tenantBySubdomain?.items[0].subdomain !== tenantName
    ) {
        console.log("Tenant name " + tenantName + " not found.");
    } else {
        var tn = res.data.tenantBySubdomain?.items[0];
        console.log("Tenant from dynamo: ", tn);
        tn = await getHubData(tn);
        tenantData?.push({ subdomain: tn.subdomain, date: new Date(), data: tn, hub: hub });
        localStorage.setItem("tenant", JSON.stringify(tn));
        localStorage.setItem("tenantDate", new Date().toDateString());
        localStorage.setItem("tenantURL", tn.url);
        console.log("Set tenant");
        isConnectedToTenant = true;
        return tn;
    }

    return undefined;
}
export async function GotoTenantURL(tenantid: string) {
    const host = window.location.host;
    const urlsplit = host.toLowerCase().split(".");

    if (urlsplit[0].toLowerCase() === tenantid.toLowerCase()) return;

    let skipDomain = 0;
    if (urlsplit[0] !== "localhost" && urlsplit[0] !== "127.0.0.0" && urlsplit.length > 2) {
        skipDomain = 1;
    }
    let url = window.location.protocol + "//" + tenantid;
    for (let int = skipDomain; int < urlsplit.length; int++) {
        url += "." + urlsplit[int];
    }
    window.location.href = url;
    return url;
}
export async function GetTenantInfo() {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    console.log("host", host);
    console.log("subdomain", subdomain);
    const tenantName = subdomain;

    return await GetTenantInfoFromName(tenantName);
}

var isConnectedToTenant = true;

function SetConnectedToTenant() {
    try {
        GetTenantInfo()
            .then((tenantInfo) => {
                if (tenantInfo) {
                    console.log("SetConnectedToTenant: tenantInfo: ", tenantInfo);
                    isConnectedToTenant = true;
                } else isConnectedToTenant = false;
            })
            .catch((err) => {
                console.log("Error setting connected to tenant2: ", err);
            });
    } catch (err) {
        console.log("Error setting connected to tenant: ", err);
    }
}
SetConnectedToTenant();

export function IsConnectedToTenant() {
    return isConnectedToTenant;
}

/*
function parseJwt (token: string | null) {
    if(token === null)
    {
        console.log("idToken is null.");
        return "";
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    console.log(jsonPayload);

    return JSON.parse(jsonPayload);
}*/

export async function GetSetting(settingName: string, defaultValue: string) {
    const settings = await GetSettings();
    if (!settings) {
        console.log("GetSetting: Settings not found.  Looking for ", settingName);
        return defaultValue;
    }

    var setting: Settings;

    try {
        console.log("GetSetting: find setting in", settings);
        setting = settings.find(
            (x: Settings) => x.name.trim().toLowerCase() === settingName.trim().toLowerCase(),
        );
    } catch (err) {
        console.log(
            "GetSetting: error finding setting.  Looking for " + settingName + ".  Error: ",
            err,
        );
        return defaultValue;
    }

    if (!setting) {
        console.log("GetSetting: Setting not found.", settingName);
        return defaultValue;
    }
    return setting.value;
}

export async function GetSettingsRefresh() {
    localStorage.removeItem("settings");
    return GetSettings();
}

function FormatSettings(settings: any) {
    var newSettings: Settings[] = [];

    if (!settings) {
        return defaultSettings;
    }

    for (var i = 0; i < settings.length; i++) {
        var setting = settings[i];
        var newSetting = defaultSettings.find(
            (x) => x.name.trim().toLowerCase() === setting.name.trim().toLowerCase(),
        );
        if (newSetting) {
            newSetting.value = setting.value;
            newSettings.push(newSetting);
        }
    }

    return newSettings;
}

export async function GetSettings() {
    try {
        var parsedData = null;

        var storedData = localStorage.getItem("settings");
        if (storedData && storedData !== null && storedData.length > 0) {
            console.log("GetSettings: Stored Data about to parse", storedData);
            parsedData = JSON.parse(storedData.toString());
            if (parsedData?.tenant && parsedData?.dcreate) {
                if (parsedData.tenant !== GetTenantName()) {
                    console.log("GetSettings: Tenant name mismatch.");
                    parsedData = null;
                } else {
                    var dcreate = new Date(parsedData.dcreate);
                    var now = new Date();
                    var diff = now.getTime() - dcreate.getTime();
                    var diffMins = diff / (1000 * 60);
                    if (diffMins > SettingsExpirationMinutes) {
                        console.log("GetSettings: Settings expired.");
                        parsedData = null;
                    } else {
                        var decryptedData = decrypt(parsedData.Settings.toString());
                        parsedData = JSON.parse(decryptedData);
                        console.log("GetSettings: Stored Settings found.", parsedData);
                    }
                }
            } else {
                console.log("GetSettings: Stored Settings not found.  Calling API.");
                parsedData = null;
            }
        }
        if (!parsedData || parsedData === null) {
            console.log("GetSettings: No stored settings found.  Calling API.");
            parsedData = await SetSettings();
        }

        if (!parsedData || parsedData === null) {
            console.log("GetSettings: No settings found. Using default settings.");
            return defaultSettings;
        }
        console.log("GetSettings: Parsed Data", parsedData);
        return parsedData;
    } catch (err) {
        console.log("GetSettings: Error parsing settings: ", err);
        storedData = null;
    }
    return defaultSettings;
}

export async function SetSettings() {
    var token = await API_Authenticate();
    var settings = await API_GetSettings(token);
    console.log("SetSettings: Settings", settings);
    if (!settings) {
        return defaultSettings;
    }
    var newJSON = FormatSettings(settings.Settings);
    var jsonString = JSON.stringify(newJSON);
    var encryptedData = encrypt(jsonString); ///Haven't been able to get encryption to work
    localStorage.setItem(
        "settings",
        JSON.stringify({ Settings: encryptedData, dcreate: new Date(), tenant: GetTenantName() }),
    );
    console.log("SetSettings: Encrypted Data", encryptedData);
    return newJSON;
}

export async function GetAuthToken() {
    try {
        var storedData = localStorage.getItem("authtoken");
        if (storedData && storedData !== null && storedData.length > 0) {
            console.log("Token: Stored Data about to parse", storedData);
            var parsedData = JSON.parse(storedData);
            if (parsedData?.dcreate) {
                var dcreate = new Date(parsedData.dcreate);
                var now = new Date();
                var diff = now.getTime() - dcreate.getTime();
                var diffMins = diff / (1000 * 60);
                if (diffMins > TokenExpirationMinutes) {
                    console.log("GetSettings: Settings expired.");
                    return SetAuthToken();
                } else {
                    storedData = JSON.stringify(parsedData.Token);
                    var token = decrypt(storedData);
                    console.log("GetAuthToken: Token found.", token);
                    return token;
                }
            }
        }

        return SetAuthToken();
    } catch (err) {
        console.log("GetAuthToken: Error getting AuthToken: ", err);
        storedData = null;
    }
    //Try to get auth token from api one more time after exception
    try {
        return SetAuthToken();
    } catch (err) {
        console.log("GetAuthToken: Error getting AuthToken again: ", err);
        return "";
    }
}

export async function SetAuthToken() {
    var token = await API_Authenticate_GetToken();
    if (!token) {
        throw new Error("Could not authenticate user.");
    }
    token = token.replaceAll('"', "");
    console.log("SetAuthToken: Token", token);
    var encryptedData = encrypt(token); ///Haven't been able to get encryption to work
    var authtoken = JSON.stringify({ Token: encryptedData, dcreate: new Date() });
    console.log("SetAuthToken: Encrypted Data", authtoken);
    localStorage.setItem("authtoken", authtoken);

    return token;
}

/*
const crypto = require('crypto');
//const algorithm = 'des-ede';
// Defining algorithm
const algorithm = 'aes-256-cbc';
 
// Defining key
const ENCRYPTION_KEY = crypto.randomBytes(32);

const ENCRYPTION_KEYs = [
      43,
      57,
      97,
      -68,
      -63,
      -61,
      -40,
      9,
      50,
      87,
      -104,
      101,
      63,
      34,
      -78,
      60,
    ];

const initialization_vector = "B03ITQaqdcnYq3W6"; // Must be 16 characters
//const secret = 'Prsm33vskjio!dfMNPrsm33vskjio!dfMN';
//let  = crypto.createHash('sha256').update(String(secret));
function encrypt(text: string){
    console.log("encrypting: " + text);
    console.log("key: " + ENCRYPTION_KEY);
  const cipher = crypto.createCipheriv(algorithm,Buffer.from(ENCRYPTION_KEY), Buffer.from(initialization_vector))
  var crypted = cipher.update(text, 'utf8', 'hex')
  crypted += cipher.final('hex')
  console.log("encrypted text: " + crypted);
  return crypted
}

function decrypt(text: string){
  const decipher = crypto.createDecipheriv(algorithm,Buffer.from(ENCRYPTION_KEY), Buffer.from(initialization_vector))
  let dec = decipher.update(text, 'hex', 'utf8')
  dec += decipher.final('utf8')
  return dec
}
*/

export function getSelectedHub() {
    var hub = localStorage.getItem("hub");
    if (hub) {
        return hub;
    }

    return "";
}

export async function getUserGroups(): Promise<string[]> {
    var groupsret: string[] = [];
    try {
        const res = await fetchAuthSession();
        if (!res) {
            console.log("Could not fetch auth session.");
            return [];
        }
        const groups = res.tokens?.accessToken.payload["cognito:groups"];
        console.log("myGroups:", groups);

        if (groups) {
            return groups as string[];
            /*JSON.parse(groups.toString()).forEach((group: string) => {
                groupsret.push(group);
            })*/
        }
    } catch (err) {
        console.log("Error getting groups: ", err);
    }

    return groupsret;
}

function encrypt(text: string) {
    return text;
}

function decrypt(text: string) {
    return text;
}

export async function setAWSTokens() {
    const res = await fetchAuthSession();

    if (!res) {
        console.log("Could not fetch auth session.");
        return;
    }

    console.log("res: ", res);
    console.log("myIDToken:", res.tokens?.idToken);

    let accessToken = res.tokens?.accessToken?.toString();
    if (!accessToken) {
        accessToken = res.credentials?.sessionToken?.toString();
    }
    console.log("myAccessToken:", accessToken);

    //let accessToken = res.tokens?.idToken?.toString();
    if (accessToken) {
        cognitoauthtoken = accessToken;
        console.log("Access Token: ", accessToken);
    }

    let idToken = res.tokens?.idToken;
    if (idToken) {
        parsedToken = idToken.payload; //parseJwt(idToken);
        tenant = parsedToken["custom:TenantId"];
        companyname = parsedToken["custom:Company"];
        //cognitoclientid = parsedToken["aud"];
        //cognitodomain = "test-prismvs.auth.us-east-1.amazoncognito.com";
        username = parsedToken.email;
        localStorage.setItem("user", username);
    } else {
    }

    return cognitoauthtoken;
}

export function handleSignOut() {
    const redirectdomain = "test-prismvs.com";
    //const logouturl = `https://${cognitodomain}/logout?client_id=${cognitoclientid}&response_type=code&redirect_uri=https%3A%2F%2F${redirectdomain}%2Fparseauth`;
    const logouturl = `https://${redirectdomain}/signout`;
    window.location.href = logouturl;
}

async function getURL() {
    if (url !== "") return url;

    await setAWSTokens();
    console.log("Getting tenant...");
    const tenantObj = await GetTenant();
    console.log("secrets tk id: " + cognitoauthtoken);
    console.log("tenant object", tenantObj);

    url = tenantObj.url;
    if (!url.endsWith("/")) url = url + "/";

    return url;
}

export async function fetchSecrets() {
    try {
        await setAWSTokens();
        console.log("Getting tenant...");
        const tenantObj = await GetTenant();
        console.log("secrets tk id: " + cognitoauthtoken);
        console.log("tenant object", tenantObj);

        url = tenantObj.url;
        if (!url.endsWith("/")) url = url + "/";
        authuserid = tenantObj.createdby;
        authKey = tenantObj.uniqueid;

        console.log("HERE AT FETCH SECRETS");
        console.log("   Tenant: " + tenantObj.name);
        console.log("   URL: " + url);
        console.log("   User: " + authuserid);
        console.log("   AuthKey: " + authKey);

        /*
        //const response = await fetch('https://ns0kfl3u2m.execute-api.us-east-1.amazonaws.com/prism-api-secrets?SecretId=TenantID_' + tenant, {
        const response = await fetch('https://ns0kfl3u2m.execute-api.us-east-1.amazonaws.com/prism-api-secrets?SecretId=' + tenantObj.name, {
                method: 'GET', 
            headers: {
                'Authorization': (cognitoauthtoken ? cognitoauthtoken : "")
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch secrets');
        }

        const data = await response.json();

        url = data.URL;
        if(!url.endsWith("/"))
        url = url + "/";
        authuserid = data.User;
        authKey = data.AuthKey;
        console.log("Secrets:" + JSON.stringify(data));
        */
    } catch (error) {
        console.error("Error fetching secrets:", error);
    }
}

//fetchSecrets();
//getSecrets2();

export function GetCompanyName() {
    return companyname;
}

export function SetCompanyLogo(logourl: string) {
    companylogo = logourl;
}

export function GetCompanyLogo() {
    if (companylogo === "") return GetLogoURL();

    return companylogo;
}

export function GetCompanySideImage() {
    if (companySideImage === "") GetLogoURL();

    return companySideImage;
}

export function GetTenantName() {
    var tennt = GetTenant();
    if (tennt) {
        return tennt.subdomain;
    }

    return "";
}

export function GetTenant() {
    const tenantStr = localStorage.getItem("tenant");
    if (tenantStr && tenantStr !== "") {
        try {
            const tenantObj = JSON.parse(tenantStr);
            return tenantObj;
        } catch (err) {
            console.log("tenantStr: ", tenantStr);
            console.log("Error parsing tenant: ", err);
        }
    }

    return null;
}

export function GetAuthKey() {
    if (authKey === "")
        return (
            fetchSecrets().then(() => {
                return authKey;
            }) || ""
        );
    else return authKey;
}

export async function API_Authenticate() {
    try {
        var token = await GetAuthToken();

        if (!token) return "";

        token = token.replaceAll('"', "").replaceAll("\\", "");

        console.log("API_Authenticate: Token found.", token);

        return token;
    } catch (err) {
        console.log("API_Authenticate: Error", err);
    }

    return "";
}

export async function API_Authenticate_GetToken() {
    url = await getURL();

    authKey = await GetAuthKey();

    console.log("url: " + url + ENDPOINT_Auth);
    console.log("authuserid: " + authuserid);
    console.log("authkey: " + authKey);

    return fetch(url + ENDPOINT_Auth, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ UserID: authuserid, AuthKey: authKey }),
    })
        .then((response) => handleResponse(response, false))
        .catch(handleAPIError);
}

function getHeaders(token: string) {
    return {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token.replaceAll('"', "")}`,
    };
}

function getHeadersTest(token: string) {
    return {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token.replaceAll('"', "")}`,
    };
}

/* res.arrayBuffer(), res.json()})
        .then(data => {*/

function showPDF(blob: Blob) {
    var _global = window;
    /*typeof window === "object" && window.window === window
                    ? window
                    : typeof self === "object" && self.self === self
                    ? self
                    : typeof global === "object" && global.global === global
                    ? global
                    : window;*/

    var isMacOSWebView =
        _global.navigator &&
        /Macintosh/.test(navigator.userAgent) &&
        /AppleWebKit/.test(navigator.userAgent) &&
        !/Safari/.test(navigator.userAgent);

    var popup = _global.open("", "_blank");
    if (popup) {
        popup.document.title = popup.document.body.innerText = "downloading...";
    }

    var force = blob.type === "application/octet-stream";
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);

    if (
        (isChromeIOS || (force && isSafari) || isMacOSWebView) &&
        typeof FileReader !== "undefined"
    ) {
        var reader = new FileReader();
        reader.onloadend = function () {
            var res = reader.result;
            if (!res) return;

            var url = res.toString();
            url = isChromeIOS ? url : url.replace(/^data:[^;]*;/, "data:attachment/file;");
            if (!url) return;

            if (popup) popup.location.href = url;
            else window.open(url);
            popup = null;
        };
        reader.readAsDataURL(blob);
    } else {
        var URL = _global.URL || _global.webkitURL;
        var url = URL.createObjectURL(blob);
        if (popup) popup.location = url;
        else _global.location.href = url;
        popup = null;
        setTimeout(function () {
            URL.revokeObjectURL(url);
        }, 4e4);
    }
}

export function API_GetInvoiceReport(token: string, cuid: string, cinvno: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetInvoiceReport, {
            method: "POST",
            headers: getHeadersTest(token),
            body: JSON.stringify({ cinvcuid: cuid, cinvno: cinvno }),
        })
            .then((res) => {
                console.log("here 1");
                if (res.ok) {
                    console.log("here 2");
                    res.arrayBuffer().then((data) => {
                        console.log("buffer ", data);

                        var newBlob = new Blob([data], { type: "application/pdf" });

                        showPDF(newBlob);
                        //console.log("blob: ", newBlob);
                        const pdf = window.URL.createObjectURL(newBlob);
                        //const pdf = window.URL.createObjectURL(data);
                        console.log("pdf: ", pdf);
                        //window.open(pdf);
                        return pdf;
                    });
                } else {
                    return res.text().then((text) => {
                        throw new Error(text);
                    });
                    /*console.log("here 3");
                    console.log("Res: ", res);
                    handleResponse(res);*/
                }
                /*var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = pdf;
                link.download = "Faktura.pdf";
                link.click();
                window.URL.revokeObjectURL(pdf);
                link.remove();*/
            })
            .catch(handleAPIError);
    });
}

export function API_GetTicketReport(token: string, username: string, csono: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetTicketReport, {
            method: "POST",
            headers: getHeadersTest(token),
            body: JSON.stringify({ username: username, csono: csono }),
        })
            .then((res) => {
                console.log("here 1");
                if (res.ok) {
                    console.log("here 2");
                    res.arrayBuffer().then((data) => {
                        console.log("buffer ", data);

                        var newBlob = new Blob([data], { type: "application/pdf" });
                        showPDF(newBlob);
                        //console.log("blob: ", newBlob);
                        const pdf = window.URL.createObjectURL(newBlob);
                        //const pdf = window.URL.createObjectURL(data);
                        console.log("pdf: ", pdf);
                        //window.open(pdf);
                        return pdf;
                    });
                } else {
                    return res.text().then((text) => {
                        throw new Error(text);
                    });
                    /*console.log("here 3");
                    console.log("Res: ", res);
                    handleResponse(res);*/
                }
                /*var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = pdf;
                link.download = "Faktura.pdf";
                link.click();
                window.URL.revokeObjectURL(pdf);
                link.remove();*/
            })
            .catch(handleAPIError);
    });
}

export function API_GetWebItems(
    token: string,
    username: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
    categories: string[],
    filterid: string,
    page: number,
    pageSize: number,
    searchString: string,
    currentItems: string[],
    sort: string,
) {
    const body = JSON.stringify({
        username: username,
        ccustno: ccustno,
        cscono: cscono,
        cschedid: cschedid,
        categories: categories,
        filterid: filterid,
        currentpage: page,
        numitemsonpage: pageSize,
        search: searchString,
        sendlog: "1",
        currentcart: currentItems,
        sort: sort,
        chubno: getSelectedHub(),
    });

    console.log("API_GetWebItems: ", body);

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebItems, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetWebItem(token: string, username: string, citemno: string) {
    const body = JSON.stringify({
        username: username,
        ccustno: "",
        cscono: "",
        cschedid: "",
        categories: "",
        filterid: "",
        currentpage: 1,
        numitemsonpage: 1,
        search: "",
        sendlog: "1",
        currentcart: "",
        sort: "",
        chubno: getSelectedHub(),
        citemno: citemno,
    });

    console.log("API_GetWebItem: ", body);

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebItems, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetSettings(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSettings, {
            method: "POST",
            headers: getHeaders(token),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetSpecialImages(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSpecialImages, {
            method: "POST",
            headers: getHeaders(token),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export type SpecialImage = {
    uuid: string;
    calt: string;
    cfilename: string;
    cfullfilename: string;
    ctitle: string;
    bactive: boolean;
    norder: number;
    centerby: string;
};

export function API_SaveSpecialImage(token: string, specialImage: SpecialImage) {
    const body = JSON.stringify({
        uuid: specialImage.uuid || "",
        cfilename: specialImage.cfilename || "",
        cfullfilename: specialImage.cfullfilename,
        centerby: specialImage.centerby || GetUserName(),
        calt: specialImage.calt,
        ctitle: specialImage.ctitle || "",
        bactive: specialImage.bactive,
        norder: specialImage.norder || 1,
    });
    console.log("body: ", body);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveSpecialImage, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUsersHubs(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUsersHubs, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SaveSettings(token: string, settings: Settings[]) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveSettings, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ Settings: settings }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetWebCategories(token: string, username: string) {
    console.log("API_GetWebCategories: ", token, username);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebCategories, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserLinkedCustomers(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetCustomers, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_RegisterNewUser(
    token: string,
    ccustno: string,
    username: string,
    bypass: boolean,
) {
    const bypassStr = bypass ? "true" : "false";

    console.log(
        "API_RegisterNewUser: ccustno: " +
        ccustno +
        " username: " +
        username +
        " token: " +
        token +
        " byp: " +
        bypassStr,
    );

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_RegisterNewUser, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                username: username,
                ccustno: ccustno,
                bpa: bypassStr,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function ClearLocalStorage() {
    localStorage.clear();
}

export function API_RemoveCustomerFromUser(token: string, ccustno: string, username: string) {
    console.log("ccustno: " + ccustno + " username: " + username + " token: " + token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_RemoveCustomerFromUser, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ username: username, ccustno: ccustno }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetNextDeliveryDate(token: string, ccustno: string, cschedid: string) {
    console.log("ccustno: " + ccustno + " cschedid: " + cschedid + " token: " + token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetNextDeliveryDate, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserInvoices(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUserInvoices, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserTickets(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUserTickets, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetLogoURL(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetLogoURL, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*export async function GetLogoURL()
{
    if(authKey == "")
        await getSecrets2();
    const token = await API_Authenticate();
    const resp = await API_GetLogoURL(token, username);
    if(resp)
        companylogo = resp.url;

    return companylogo;
}*/

export async function GetLogoURL() {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    const t = await GetTenantInfoFromName(subdomain);
    if (!t) {
        console.log("Tenant for subdomain " + subdomain + " not found.  Showing default.");
        console.log("Logo: Default");
        companylogo = defaultLogo;
        companySideImage = defaultSideImage;
        return defaultLogo;
    }

    if (t.logo && t.logo !== "") {
        //setTenant(t);
        console.log("tenant: ", t);
        companylogo = t.logo;
        console.log("Logo: ", companylogo);
        console.log("883: Side Image: ", t.loginsideimage);
        companySideImage =
            t.loginsideimage && t.loginsideimage !== "" ?
                t.loginsideimage
                : "https://prism-web-images.s3.amazonaws.com/login_images/" + subdomain + ".jpg";
    } else {
        console.log("Showing default tenant.");
        console.log("Logo: Default");
        companylogo = defaultLogo;
        companySideImage = defaultSideImage;
    }

    return companylogo;
}

/*  Add Items to Schedule */

export type OrderItems = {
    citemno: string;
    nqty: number;
};

export function API_AddItemsToSchedule(
    token: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
    orderitems: OrderItems[],
) {
    return fetch(url + ENDPOINT_AddItemsToSchedule, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            ccustno: ccustno,
            cscono: cscono,
            cschedid: cschedid,
            items: orderitems,
        }),
    })
        .then(handleResponse)
        .catch(handleAPIError);
}

/*  End add Items to Schedule */

export function API_GetCustomerCheckoutInfo(
    token: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
) {
    return fetch(url + ENDPOINT_GetCustomerCheckoutInfo, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            ccustno: ccustno,
            cscono: cscono,
            cschedid: cschedid,
        }),
    })
        .then(handleResponse)
        .catch(handleAPIError);
}

/*  Create Ticket */

export function API_CreateTicket(
    token: string,
    ccustno: string,
    cschedid: string,
    cpono: string,
    corderby: string,
    cpaycode: string,
    cmessage: string,
    total: number,
    chargecc: number = 0,
    ccprofid: string,
    shipdate: Date,
    deliverydate: Date,
) {
    console.log(
        "API_CreateTicket: ",
        JSON.stringify({
            ccustno: ccustno,
            cschedid: cschedid,
            cpono: cpono,
            corderby: corderby,
            cpaycode: cpaycode,
            cmessage: cmessage,
            total: total,
            chargecc: chargecc,
            ccprofid: ccprofid,
            shipdate: shipdate,
            deliverydate: deliverydate,
        }),
    );

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_CreateTicket, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cschedid: cschedid,
                    cpono: cpono,
                    corderby: corderby,
                    cpaycode: cpaycode,
                    cmessage: cmessage,
                    total: total,
                    chargecc: chargecc,
                    customer_payment_profile_id: ccprofid,
                    shipdate: shipdate,
                    deliverydate: deliverydate,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*  End Create Ticket */

/*  Create SVC Ticket */

export function API_CreateServiceTicket(
    token: string,
    ccustno: string,
    cschedid: string,
    cpono: string,
    username: string,
    message: string,
) {
    console.log(
        "API_CreateServiceTicket: ",
        JSON.stringify({
            ccustno: ccustno,
            cschedid: cschedid,
            cpono: cpono,
            corderby: username,
            cmessage: message,
        }),
    );

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_CreateServiceTicket, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cschedid: cschedid,
                    cpono: cpono,
                    username: username,
                    message: message,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*  End Create Ticket */

/* Get Suggested Items */
export function API_GetSuggestedItems(
    token: string,
    citemno: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSuggestedItems, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                citemno: citemno,
                ccustno: ccustno,
                cscono: cscono,
                cschedid: cschedid,
                chubno: getSelectedHub(),
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Suggested Items */

/* Get Schedule Header */
export function API_GetScheduleHeader(token: string, ccustno: string, cschedid: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleHeader, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Header */

/* Get Schedule Case Count */
export function API_GetScheduleCaseCount(token: string, ccustno: string, cschedid: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleCaseCount, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Case Count */

export function API_GetOpenInvoices(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_OpenInv, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* Save CC Profile */
export function API_SaveCCProfile(
    token: string,
    ccprofile: CCProfile,
    ccustno: string,
    username: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                PaymentCardProfile: JSON.stringify(
                    ccprofile,
                    (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
                ),
                ccustno: ccustno,
                username: username,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* End Save CC Profile */

export function API_DeleteCCProfile(token: string, nprofid: string) {
    console.log("deleting nprofid: ", nprofid.toString());
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_DeleteCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ nprofid: nprofid.toString() }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SetDefaultCCProfile(token: string, nprofid: string) {
    console.log("updating nprofid: ", nprofid.toString());
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SetDefaultCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ nprofid: nprofid.toString() }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* Get Schedule Addresses */
export function API_GetScheduleAddresses(token: string, username: string, ctype: string) {
    console.log("API_GetScheduleAddresses user: ", username);
    console.log("API_GetScheduleAddresses token:", token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleAddress, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username, ctype: ctype }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Addresses */

/* Get Surcharge */
export function API_GetSurcharge(
    token: string,
    ccustno: string,
    cinvno: string,
    nbalance: number,
    customer_profile_id: bigint,
    customer_payment_profile_id: bigint,
    username: string,
) {
    console.log(
        "API_GetSurcharge Request: ",
        JSON.stringify(
            {
                ccustno: ccustno,
                cinvno: cinvno,
                nbalance: nbalance,
                customer_profile_id: customer_profile_id,
                customer_payment_profile_id: customer_payment_profile_id,
                username: username,
            },
            (key, value) => (typeof value === "bigint" ? value.toString() : value),
        ),
    );
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSurcharge, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cinvno: cinvno,
                    nbalance: nbalance,
                    customer_profile_id: customer_profile_id,
                    customer_payment_profile_id: customer_payment_profile_id,
                    username: username,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value),
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Surcharge */

export function API_GetPaymentMethods(ccustno: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_PayMethods, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, ctype: "CC" }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SetPONumber(ponumber: string, invcuid: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SetPONum, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                cpono: ponumber,
                cinvcuid: invcuid,
                username: username,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetCustomerCredits(ccustno: string, cscono: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetCustomerCredits, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno, cscono, username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*export function API_GetCustomerCredits(ccustno: string): Promise<number> {
    return new Promise((resolve, reject) => {
        if (ccustno.trim() === "01-000306") {
            resolve(20.00);
        } else {
            resolve(0);
        }
    });
}*/

export function API_PayInvoices(
    InvoiceCuids: any,
    total: number,
    customer_profile_id: any,
    customer_payment_profile_id: any,
    useCredits: boolean,
    username: string,
    token: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_PayInvoices, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    InvoiceCuids,
                    total,
                    customer_profile_id,
                    customer_payment_profile_id,
                    useCredits,
                    username,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged)
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*
export function API_PayByCreditCard(
    token: string,
    total: number,
    customer_payment_profile_id: any,
    ccustno: string,
    cscono: string,
    csono: string,
    ccompany: string,
    username: string
  ) {
    console.log("Pay by CC custno: ", ccustno);
    console.log("Pay by CC profid: ", customer_payment_profile_id);
    console.log("Pay by CC username: ", username);
    console.log("Pay by CC token: ", token);
    
    
    return fetch(url + ENDPOINT_PayByCreditCard, {
        method: 'POST',
        headers:  getHeaders(token),
        body: JSON.stringify({ total, customer_payment_profile_id, ccustno, cscono, csono, ccompany, username } , (key, value) =>
        typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged)
    
        )}
    )
    .then(handleResponse)
    .catch(handleAPIError);
}*/

// Functions below are not accessible outside of the prismapi.tsx document. Only meant to be used with the above functions.

function handleResponse(response: Response, expectsJson = true): Promise<any> {
    console.log("Response: ", response);
    if (!response.ok) {
        return response.json().then((error) => {
            throw new Error(error.message || "Server Error!");
        });
    }
    return expectsJson ? response.json() : response.text();
}

/*
function handleArrayBufferResponse(response: Response): Promise<any> {
    if (!response.ok) {
        return response.json().then(error => {
            throw new Error(error.message || 'Server Error!');
        });
    }
    return response.arrayBuffer();
}*/

export function GetUserName(): string {
    return localStorage.getItem("user") || "";
}

function handleAPIError(error: any) {
    if (!error) console.error("API Request failure: Error is undefined");

    console.error("API Request Failure: ", error);
    console.log("API Request Failure: ", error.message);
    //throw error;
}
